import { PropsWithChildren } from "react";
import { Layout } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";

const { Sider } = Layout;

export function AppSidebar({ children }: PropsWithChildren<{}>) {
  return (
    <Sider className={cn(styles.sidebar)} style={{ position: "fixed" }}>
      {children}
    </Sider>
  );
}
