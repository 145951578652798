import {
  Avatar,
  Card,
  Descriptions,
  DescriptionsProps,
  Dropdown,
  MenuProps,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { Deployment } from "../model";
import { useMemo } from "react";
import { useGtagClicks } from "shared/hooks";

interface DeploymentCardProps {
  deployment: Deployment;
  onDeploymentClick?: () => void;
  gtagClicks?: ReturnType<typeof useGtagClicks>;
}

function getMenuOptions(
  deployment: Deployment,
  onDeploymentClick?: () => void,
  gtagNotify?: (name: string) => void
): MenuProps["items"] | null {
  const disabled = deployment.status === "pending";
  if (deployment.status === "deleted") {
    return null;
  }
  return [
    onDeploymentClick
      ? {
          key: "view",
          label: <Typography.Text>View</Typography.Text>,
          onClick: () => {
            gtagNotify?.call(null, "deployment-card-view-click");
            onDeploymentClick?.call(null);
          },
        }
      : null,
    deployment.status === "running"
      ? {
          key: "pause",
          disabled,
          label: <Typography.Text disabled={disabled}>Pause</Typography.Text>,
          onClick: () => {
            gtagNotify?.call(null, "deployment-card-pause-click");
          },
        }
      : {
          key: "start",
          disabled,
          label: <Typography.Text disabled={disabled}>Start</Typography.Text>,
          onClick: () => {
            gtagNotify?.call(null, "deployment-card-start-click");
          },
        },
    {
      key: "terminate",
      label: (
        <Typography.Text type="danger" disabled={disabled}>
          Terminate
        </Typography.Text>
      ),
      onClick: () => {
        gtagNotify?.call(null, "deployment-card-terminate-click");
      },
    },
  ].filter(Boolean);
}

export function DeploymentCard({
  deployment,
  onDeploymentClick,
  gtagClicks,
}: DeploymentCardProps) {
  const desriptionsItems: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "status",
        label: "Status",
        children: <Typography.Text>{deployment.status}</Typography.Text>,
      },
      {
        key: "created",
        label: "Created",
        children: (
          <Typography.Text>{deployment.created.toISOString()}</Typography.Text>
        ),
      },
    ],
    [deployment.status, deployment.created]
  );

  const menuOptions = useMemo(
    () => getMenuOptions(deployment, onDeploymentClick, gtagClicks),
    [deployment, onDeploymentClick, gtagClicks]
  );
  return (
    <Card
      extra={
        <Dropdown.Button
          menu={{ items: menuOptions || [] }}
          disabled={menuOptions === null}
        >
          Actions
        </Dropdown.Button>
      }
    >
      <Space direction="vertical">
        <Card.Meta
          avatar={
            <Avatar
              src={`${process.env.PUBLIC_URL}/OptimismLogo.svg`}
              size={48}
            />
          }
          title={deployment.name}
          description={`OP Stack (${deployment.l1Target})`}
        />
        <Row>
          <Descriptions layout="vertical" items={desriptionsItems} />
        </Row>
      </Space>
    </Card>
  );
}

interface DeploymentCardSkeletonProps {
  active?: boolean;
}

export function DeploymentCardSkeleton({
  active,
}: DeploymentCardSkeletonProps) {
  const desriptionsItems: DescriptionsProps["items"] = [
    {
      key: "status",
      label: "Status",
      children: <Skeleton.Input size="small" active={active} />,
    },
    {
      key: "created",
      label: "Created",
      children: <Skeleton.Input size="small" active={active} />,
    },
  ];
  return (
    <Card extra={<Skeleton.Button />}>
      <Space direction="vertical">
        <Card.Meta
          avatar={<Skeleton.Avatar size={48} active={active} />}
          title={<Skeleton.Input size="large" active={active} />}
          description={<Skeleton.Input size="small" active={active} />}
        />
        <Row>
          <Descriptions layout="vertical" items={desriptionsItems} />
        </Row>
      </Space>
    </Card>
  );
}
