import { sessionSlice } from "entities/session";
import { combineReducers } from "@reduxjs/toolkit";
import { baseApi } from "shared/api";
import { deploymentSlice } from "entities/deployment";
import { operatorSlice } from "entities/requests";

export const rootReducer = combineReducers({
  [sessionSlice.name]: sessionSlice.reducer,
  [deploymentSlice.name]: deploymentSlice.reducer,
  [operatorSlice.name]: operatorSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});
