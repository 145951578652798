import { useGA4React } from "ga-4-react";
import { useEffect, useCallback } from "react";
import { useLocation } from "react-router";

export function usePageView() {
  const ga = useGA4React();
  const location = useLocation();

  useEffect(() => {
    if (typeof ga === "object") {
      const title = location.pathname.split("/").join(" ").trim();
      ga.gtag("config", process.env.REACT_APP_GTAG_ID, {
        screen_name: title,
        app_name: "scalind_app",
      });
      ga.gtag("event", "page_view", {
        page_location: location,
        page_title: title,
      });
    }
  }, [ga, location]);
}

export function useGtagClick(buttonName: string): () => void {
  const ga = useGA4React();

  return useCallback(() => {
    if (typeof ga === "object") {
      ga.gtag("event", "click", {
        button: buttonName,
      });
    }
  }, [ga, buttonName]);
}

export function useGtagClicks(): (buttonName: string) => void {
  const ga = useGA4React();

  return useCallback(
    (buttonName: string) => {
      if (typeof ga === "object") {
        ga.gtag("event", "click", {
          button: buttonName,
        });
      }
    },
    [ga]
  );
}
