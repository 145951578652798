import { PropsWithChildren } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Row, Spin, Typography } from "antd";
import { LoginButton } from "widgets/user-actions";
import { useAppSelector } from "shared/model/hooks";
import { selectIsAuthorized } from "entities/session";

export function RequireLogin({ children }: PropsWithChildren) {
  const { isLoading } = useAuth0();
  const isAuthenticated = useAppSelector(selectIsAuthorized);

  if (isLoading) {
    return (
      <Row justify="center" align="middle">
        <Spin tip="Loading..." size="large" />
      </Row>
    );
  }

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return (
      <Row justify="center" align="stretch">
        <Card title="Login required">
          <Typography.Paragraph type="secondary">
            You need to register or sign up to use this feature
          </Typography.Paragraph>
          <Row justify="center">
            <LoginButton />
          </Row>
        </Card>
      </Row>
    );
  }
}
