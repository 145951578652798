import { Flex, Menu, MenuProps, Row, Space, Typography } from "antd";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import styles from "./styles.module.scss";

interface OperatorSiderMenuProps {
  selected?: string;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  navigate: ReturnType<typeof useNavigate>,
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: (
      <Typography.Link onClick={() => navigate(`/${key}`)}>
        {label}
      </Typography.Link>
    ),
    type,
  } as MenuItem;
}

export function OperatorSiderMenu({ selected }: OperatorSiderMenuProps) {
  const navigate = useNavigate();

  const menu: Array<MenuItem> = useMemo(
    () => [
      getItem(navigate, "Requests", "operator/requests"),
      getItem(navigate, "Prepared", "operator/prepared"),
      getItem(navigate, "Completed", "operator/completed"),
    ],
    [navigate]
  );

  return (
    <Flex
      vertical
      justify="start"
      style={{ height: "inherit" }}
      className={cn(styles["menu-container"])}
    >
      <Space align="center" direction="vertical">
        <Row align="stretch">
          <Typography.Title level={2}>SC Operator</Typography.Title>
        </Row>
        <Menu
          items={menu}
          mode="vertical"
          selectedKeys={
            selected && menu.map((i) => i?.key).includes(selected)
              ? [selected]
              : []
          }
        />
      </Space>
    </Flex>
  );
}
