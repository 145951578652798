import { Col, Row, Skeleton } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";

interface OperatorRequestSkeletonRowProps {
  active?: boolean;
}

export function OperatorRequestSkeletonRow({
  active,
}: OperatorRequestSkeletonRowProps) {
  return (
    <Row align="middle" justify="space-between" className={cn(styles.row)}>
      <Col span={4}>
        <Skeleton.Avatar size={40} active={active} />
      </Col>
      <Col span={4}>
        <Skeleton.Input size="large" active={active} />
      </Col>
      <Col span={4}>
        <Skeleton.Input active={active} />
      </Col>
      <Col span={12}>
        <Skeleton.Button />
      </Col>
    </Row>
  );
}
