import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DeploymentsState } from "./types";
import { deploymentsApi } from "../api/deployment-api";

const initialState: DeploymentsState = {
  page: 0,
  pageSize: 10,
};

export const deploymentSlice = createSlice({
  name: "deployments",
  initialState,
  reducers: {
    nextPage: (state) => {
      state.page += 1;
    },
    prevPage: (state) => {
      state.page = Math.max(0, state.page - 1);
    },
    selectPage: (state, page: PayloadAction<number>) => {
      state.page = Math.max(0, page.payload);
    },
    cleanupCreatedDeployment: (state) => {
      delete state.createdDeployment;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      deploymentsApi.endpoints.deploymentsList.matchFulfilled,
      (state, { payload, meta: { arg } }) => {
        state.page = arg.originalArgs.page;
        state.totalItems = payload.totalCount;
      }
    );
    builder.addMatcher(
      deploymentsApi.endpoints.createDeployment.matchFulfilled,
      (state, { payload }) => {
        state.createdDeployment = payload;
      }
    );
  },
});

export const selectPaginationInfo = ({
  deployments: { page, pageSize, totalItems },
}: RootState) => ({
  page,
  pageSize,
  totalItems,
  totalPages:
    totalItems !== undefined ? Math.ceil(totalItems / pageSize) : undefined,
});

export const selectedCreatedDeployment = (state: RootState) =>
  state.deployments.createdDeployment;

export const { nextPage, prevPage, selectPage, cleanupCreatedDeployment } =
  deploymentSlice.actions;
