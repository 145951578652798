import { Avatar, Button, Col, Row, Space, Typography } from "antd";
import { OperatorRequestedDeployment } from "../model";
import cn from "classnames";

import styles from "./styles.module.scss";

interface OperatorRequestRowProps {
  request: OperatorRequestedDeployment;
  onClick?: () => void;
  actionText: string;
}

export function OperatorRequestRow({
  request: { name, created, target, ownerEmail },
  actionText,
  onClick,
}: OperatorRequestRowProps) {
  return (
    <Row align="middle" className={cn(styles.row)}>
      <Col span={4}>
        <Avatar src="/OptimismLogo.svg" size={40} />
      </Col>
      <Col span={4}>
        <Space direction="vertical">
          <Typography.Text strong>{name}</Typography.Text>
          <Typography.Text type="secondary">
            {created.toDateString()}
          </Typography.Text>
        </Space>
      </Col>
      <Col span={4}>
        <Typography.Text>Target {target}</Typography.Text>
      </Col>
      <Col span={4}>
        <Typography.Text>
          {ownerEmail ? (
            <Typography.Text>{ownerEmail}</Typography.Text>
          ) : (
            <Typography.Text type="secondary">
              Non verified email
            </Typography.Text>
          )}
        </Typography.Text>
      </Col>
      {actionText && (
        <Col span={8}>
          <Row justify="center">
            <Button type="primary" onClick={onClick}>
              {actionText}
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
}
