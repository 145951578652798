import { selectIsAuthorized, sessionLoginDetected } from "entities/session";
import { useAppDispatch, useAppSelector } from "shared/model/hooks";
import {
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { useEffect } from "react";
import { JwtPayload, jwtDecode } from "jwt-decode";

const StoreAdapter = () => {
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims } =
    useAuth0();

  const isStoreAuthorized = useAppSelector(selectIsAuthorized);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && !isStoreAuthorized) {
      Promise.all([getAccessTokenSilently(), getIdTokenClaims()]).then(
        ([accessToken, claims]) => {
          if (claims && claims.name && claims.email) {
            const decodedAccessToken = jwtDecode<
              JwtPayload & { scope?: string }
            >(accessToken);
            dispatch(
              sessionLoginDetected({
                accessToken,
                user: {
                  fullname: claims.name,
                  email: claims.email,
                },
                scope: decodedAccessToken.scope,
              })
            );
          }
        }
      );
    }
  }, [
    isAuthenticated,
    isStoreAuthorized,
    getAccessTokenSilently,
    getIdTokenClaims,
    dispatch,
  ]);

  return <></>;
};

export const withAuth0 = (component: () => React.ReactNode) => () => {
  const config: Auth0ProviderOptions = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    authorizationParams: {
      redirect_uri: window.location.pathname.startsWith("/operator")
        ? `${window.location.origin}/operator`
        : window.location.origin,
      ...("REACT_APP_AUTH0_AUDIENCE" in process.env
        ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
        : null),
      scope: window.location.pathname.startsWith("/operator")
        ? "openid email profile operator:read operator:write"
        : "openid email profile",
    },
  };

  return (
    <Auth0Provider {...config}>
      <>
        <StoreAdapter />
        {component()}
      </>
    </Auth0Provider>
  );
};
