import { GA4R } from "ga-4-react";

export const withGtag = (component: () => React.ReactNode) => () => {
  const code = process.env.REACT_APP_GTAG_ID;
  if (code) {
    return (
      <GA4R
        code={code}
        config={{
          send_page_view: false,
          debug_mode: "REACT_APP_GTAG_DEBUG" in process.env ? true : false,
        }}
      >
        {component()}
      </GA4R>
    );
  } else {
    return component();
  }
};
