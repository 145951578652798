import { LoadingOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Typography } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "shared/model/hooks";
import { selectIsAuthorized, selectScope, selectUser } from "entities/session";

interface UserActionsProps {
  className?: string;
}

export function UserActions({ className }: UserActionsProps) {
  const { logout } = useAuth0();

  const user = useAppSelector(selectUser);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  const menu: MenuProps["items"] = [
    user
      ? {
          key: "username",
          disabled: true,
          label: (
            <Space direction="vertical" size="small">
              <Typography.Text>Hello, {user.fullname}</Typography.Text>
              {user.email && user.fullname !== user.email && (
                <Typography.Text type="secondary">{user.email}</Typography.Text>
              )}
            </Space>
          ),
        }
      : null,
    {
      key: "logout",
      label: <Typography.Text type="danger">Log Out</Typography.Text>,
      onClick: () => logoutWithRedirect(),
      icon: <PoweroffOutlined />,
    },
  ].filter(Boolean);
  return (
    <Dropdown.Button menu={{ items: menu }} className={className}>
      <Typography.Text>{user?.fullname || "Account"}</Typography.Text>
    </Dropdown.Button>
  );
}

interface LoginButtonProps {
  extraScope?: string;
  redirectUri?: string;
}

export function LoginButton({ extraScope, redirectUri }: LoginButtonProps) {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      type="primary"
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            scope: extraScope
              ? `openid profile email ${extraScope}`
              : undefined,
            redirect_uri: redirectUri
              ? window.location.origin + redirectUri
              : undefined,
          },
        })
      }
    >
      Log in
    </Button>
  );
}

type UserMultibuttonProps = LoginButtonProps;

export function UserMultibutton({
  extraScope,
  redirectUri,
}: UserMultibuttonProps) {
  const { isLoading } = useAuth0();
  const isAuthentificated = useAppSelector(selectIsAuthorized);
  const scope = useAppSelector(selectScope);
  const extraScopes = extraScope?.split(" ") ?? [];

  if (isLoading) {
    return <LoadingOutlined size={30} />;
  }

  if (
    isAuthentificated &&
    scope &&
    extraScopes.every((es) => scope.includes(es))
  ) {
    return <UserActions className={cn(styles["user-actions"])} />;
  } else {
    return <LoginButton extraScope={extraScope} redirectUri={redirectUri} />;
  }
}
