import { Layout } from "antd";
import { PropsWithChildren } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

const { Content } = Layout;

interface AppContentProps {}

export function AppContent({ children }: PropsWithChildren<AppContentProps>) {
  return <Content className={cn(styles.content)}>{children}</Content>;
}
