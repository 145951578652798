// Or use @loadable/component, as part of the tutorial - uncritically
import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./_layout";
import { RequireLogin } from "widgets/require-login";
import OperatorLayout from "./_layout/operator";

const DeploymentsPage = lazy(() => import("./deployments"));
const DeploymentViewPage = lazy(() => import("./deployment-view"));
const CreateNetworkPage = lazy(() => import("./create-network"));
const RollupsPage = lazy(() => import("./rollups"));
const IntegrationsPage = lazy(() => import("./integrations"));
const BillingPage = lazy(() => import("./billing"));
const DocsPage = lazy(() => import("./docs"));
const OperatorRequestsPage = lazy(() => import("./operator-requests"));
const OperatorCreatePreparementPage = lazy(
  () => import("./prepare-deployment")
);
const OperatorCompleteDeploymentPage = lazy(
  () => import("./complete-deployment")
);
const OperatorPreparedRequestsPage = lazy(
  () => import("./operator-prepared-requests")
);
const OperatorCompletedRequestsPage = lazy(
  () => import("./operator-completed-requests")
);
const OperatorDeploymentViewPage = lazy(
  () => import("./operator-request-view")
);

export const Routing = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <RequireLogin>
              <DeploymentsPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/deployments"
        element={
          <Layout>
            <RequireLogin>
              <DeploymentsPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/deployments/:id"
        element={
          <Layout>
            <RequireLogin>
              <DeploymentViewPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/create-network"
        element={
          <Layout withoutSider>
            <RequireLogin>
              <CreateNetworkPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/rollups"
        element={
          <Layout>
            <RequireLogin>
              <RollupsPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/integrations"
        element={
          <Layout>
            <RequireLogin>
              <IntegrationsPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/billing"
        element={
          <Layout>
            <RequireLogin>
              <BillingPage />
            </RequireLogin>
          </Layout>
        }
      />
      <Route
        path="/docs"
        element={
          <Layout>
            <DocsPage />
          </Layout>
        }
      />
      <Route
        path="/operator"
        element={
          <OperatorLayout>
            <OperatorRequestsPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/requests"
        element={
          <OperatorLayout>
            <OperatorRequestsPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/prepare/:id"
        element={
          <OperatorLayout withoutSider>
            <OperatorCreatePreparementPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/complete/:id"
        element={
          <OperatorLayout>
            <OperatorCompleteDeploymentPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/prepared"
        element={
          <OperatorLayout>
            <OperatorPreparedRequestsPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/completed"
        element={
          <OperatorLayout>
            <OperatorCompletedRequestsPage />
          </OperatorLayout>
        }
      />
      <Route
        path="/operator/request/:id"
        element={
          <OperatorLayout>
            <OperatorDeploymentViewPage />
          </OperatorLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
