import { PropsWithChildren, lazy } from "react";
import { AppSidebar } from "./sidebar";
import { SiderMenu } from "../../widgets/sider-menu";
import { AppContent } from "./content";
import { Layout, Row, Space } from "antd";
import { useLocation } from "react-router-dom";
import { UserMultibutton } from "widgets/user-actions";
import { usePageView } from "shared/hooks";

interface AppLayoutProps {
  withoutSider?: boolean;
}

export default function AppLayout({
  children,
  withoutSider = false,
}: PropsWithChildren<AppLayoutProps>) {
  const location = useLocation();
  usePageView();

  return (
    <Layout hasSider={!withoutSider}>
      {!withoutSider && (
        <AppSidebar>
          <SiderMenu selected={location.pathname.substring(1)} />
        </AppSidebar>
      )}
      <Layout
        style={{
          marginLeft: withoutSider ? 0 : 200,
        }}
      >
        <Layout.Header>
          <Row justify="end">
            <Space direction="vertical">
              <UserMultibutton />
            </Space>
          </Row>
        </Layout.Header>
        <AppContent>{children}</AppContent>
      </Layout>
    </Layout>
  );
}

export const OperatorLayout = lazy(() => import("./operator"));
