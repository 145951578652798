import {
  ApiOutlined,
  BookOutlined,
  CloudServerOutlined,
  GlobalOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Flex, Menu, MenuProps, Row, Space, Typography } from "antd";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import styles from "./styles.module.scss";

interface SiderMenuProps {
  selected?: string;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  navigate: ReturnType<typeof useNavigate>,
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: (
      <Typography.Link onClick={() => navigate(`/${key}`)}>
        {label}
      </Typography.Link>
    ),
    type,
  } as MenuItem;
}

export function SiderMenu({ selected }: SiderMenuProps) {
  const navigate = useNavigate();

  const UpperMenu: Array<MenuItem> = useMemo(
    () => [
      getItem(
        navigate,
        "My nodes",
        "deployments",
        <CloudServerOutlined size={24} />
      ),
      getItem(
        navigate,
        "Available rollups",
        "rollups",
        <GlobalOutlined size={24} />
      ),
      getItem(
        navigate,
        "Integrations",
        "integrations",
        <ApiOutlined size={24} />
      ),
    ],
    [navigate]
  );

  const BottomMenu: Array<MenuItem> = useMemo(
    () => [
      getItem(navigate, "Billing", "billing", <WalletOutlined size={24} />),
      getItem(navigate, "Documentation", "docs", <BookOutlined size={24} />),
    ],
    [navigate]
  );
  return (
    <Flex
      vertical
      justify="space-between"
      style={{ height: "inherit" }}
      className={cn(styles["menu-container"])}
    >
      <Space align="center" direction="vertical">
        <Row align="stretch">
          <Typography.Title level={2}>Scalind</Typography.Title>
        </Row>
        <Menu
          items={UpperMenu}
          mode="vertical"
          selectedKeys={
            selected && UpperMenu.map((i) => i?.key).includes(selected)
              ? [selected]
              : []
          }
        />
      </Space>
      <Menu
        items={BottomMenu}
        mode="vertical"
        selectedKeys={
          selected && BottomMenu.map((i) => i?.key).includes(selected)
            ? [selected]
            : []
        }
      />
    </Flex>
  );
}
