import { DEPLOYMENTS_TAG, baseApi } from "shared/api";
import { Deployment } from "../model";
import { CreateDeploymentDto, DeploymentDto } from "./types";
import { PageRequest, PageResponse } from "shared/model/types";

export const deploymentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deploymentsList: build.query<PageResponse<Deployment>, PageRequest>({
      query: ({ page, pageSize }) => ({
        url: `/deployments`,
        params: {
          page,
          pageSize,
        },
      }),
      providesTags: [DEPLOYMENTS_TAG],
      transformResponse: ({
        content: responseContent,
        totalCount,
      }: PageResponse<DeploymentDto>) => {
        const content = responseContent.map((d) => ({
          ...d,
          created: new Date(d.created),
        }));

        return {
          content,
          totalCount,
        };
      },
    }),
    createDeployment: build.mutation<Deployment, CreateDeploymentDto>({
      invalidatesTags: [DEPLOYMENTS_TAG],
      query: (dto) => ({
        url: "/deployments",
        method: "POST",
        body: dto,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      transformResponse: (dto: DeploymentDto) => ({
        ...dto,
        created: new Date(dto.created),
      }),
    }),
  }),
});

export const { useDeploymentsListQuery, useCreateDeploymentMutation } =
  deploymentsApi;
