import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OperatorRequestsState } from "./types";
import { operatorApi } from "../api/operator-api";

const initialState: OperatorRequestsState = {
  requested: {
    page: 0,
    pageSize: 10,
  },
  prepared: {
    page: 0,
    pageSize: 10,
  },
  completed: {
    page: 0,
    pageSize: 10,
  },
};

export const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    requestedNextPage: (state) => {
      state.requested.page += 1;
    },
    requestedPrevPage: (state) => {
      state.requested.page = Math.max(0, state.requested.page - 1);
    },
    requestedSelectPage: (state, page: PayloadAction<number>) => {
      state.requested.page = Math.max(0, page.payload);
    },
    preparedNextPage: (state) => {
      state.prepared.page += 1;
    },
    preparedPrevPage: (state) => {
      state.prepared.page = Math.max(0, state.prepared.page - 1);
    },
    preparedSelectPage: (state, page: PayloadAction<number>) => {
      state.prepared.page = Math.max(0, page.payload);
    },
    completedNextPage: (state) => {
      state.completed.page += 1;
    },
    completedPrevPage: (state) => {
      state.completed.page = Math.max(0, state.prepared.page - 1);
    },
    completedSelectPage: (state, page: PayloadAction<number>) => {
      state.completed.page = Math.max(0, page.payload);
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      operatorApi.endpoints.requested.matchFulfilled,
      (state, { payload, meta: { arg } }) => {
        state.requested.page = arg.originalArgs.page;
        state.requested.totalItems = payload.totalCount;
      }
    );
    builder.addMatcher(
      operatorApi.endpoints.prepared.matchFulfilled,
      (state, { payload, meta: { arg } }) => {
        state.prepared.page = arg.originalArgs.page;
        state.prepared.totalItems = payload.totalCount;
      }
    );
    builder.addMatcher(
      operatorApi.endpoints.completed.matchFulfilled,
      (state, { payload, meta: { arg } }) => {
        state.completed.page = arg.originalArgs.page;
        state.completed.totalItems = payload.totalCount;
      }
    );
  },
});

export const selectOperatorRequestedPaginationInfo = ({
  operator: {
    requested: { page, pageSize, totalItems },
  },
}: RootState) => ({
  page,
  pageSize,
  totalItems,
  totalPages:
    totalItems !== undefined ? Math.ceil(totalItems / pageSize) : undefined,
});

export const selectOperatorPreparedPaginationInfo = ({
  operator: {
    prepared: { page, pageSize, totalItems },
  },
}: RootState) => ({
  page,
  pageSize,
  totalItems,
  totalPages:
    totalItems !== undefined ? Math.ceil(totalItems / pageSize) : undefined,
});

export const selectOperatorCompletedPaginationInfo = ({
  operator: {
    completed: { page, pageSize, totalItems },
  },
}: RootState) => ({
  page,
  pageSize,
  totalItems,
  totalPages:
    totalItems !== undefined ? Math.ceil(totalItems / pageSize) : undefined,
});

export const {
  preparedNextPage,
  preparedPrevPage,
  preparedSelectPage,
  requestedNextPage,
  requestedPrevPage,
  requestedSelectPage,
  completedNextPage,
  completedPrevPage,
  completedSelectPage,
} = operatorSlice.actions;
