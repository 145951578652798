import compose from "compose-function";
import { withTheme } from "./with-theme";
import { withRouter } from "./with-router";
import { withAuth0 } from "./with-auth0";
import { withRedux } from "./with-redux";
import { withApp } from "./with-app";
import { withGtag } from "./with-gtag";

export const withProviders = compose(
  withTheme,
  withApp,
  withRedux,
  withRouter,
  withAuth0,
  withGtag
);
