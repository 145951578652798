import {
  OPERATOR_REQUESTS,
  PREPARED_OPERATOR_REQUESTS,
  baseApi,
} from "shared/api";
import { PageRequest, PageResponse } from "shared/model/types";
import { OperatorRequestedDeploymentDto } from "./types";
import { OperatorRequestedDeployment } from "../model";
import { COMPLETED_OPERATOR_REQUESTS } from "shared/api/tags";

function transformPageResponse({
  totalCount,
  content,
}: PageResponse<OperatorRequestedDeploymentDto>) {
  return {
    totalCount,
    content: content.map((dto) => ({
      ...dto,
      created: new Date(dto.created),
    })),
  };
}

export const operatorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    requested: builder.query<
      PageResponse<OperatorRequestedDeployment>,
      PageRequest
    >({
      query: ({ page, pageSize }) => ({
        url: "/operator/requests",
        params: {
          page,
          pageSize,
        },
      }),
      providesTags: [OPERATOR_REQUESTS],
      transformResponse: transformPageResponse,
    }),
    prepared: builder.query<
      PageResponse<OperatorRequestedDeployment>,
      PageRequest
    >({
      query: ({ page, pageSize }) => ({
        url: "/operator/prepared",
        params: {
          page,
          pageSize,
        },
      }),
      providesTags: [PREPARED_OPERATOR_REQUESTS],
      transformResponse: transformPageResponse,
    }),
    completed: builder.query<
      PageResponse<OperatorRequestedDeployment>,
      PageRequest
    >({
      query: ({ page, pageSize }) => ({
        url: "/operator/completed",
        params: {
          page,
          pageSize,
        },
      }),
      transformResponse: transformPageResponse,
      providesTags: [COMPLETED_OPERATOR_REQUESTS],
    }),
  }),
});

export const { useRequestedQuery, usePreparedQuery, useCompletedQuery } =
  operatorApi;
