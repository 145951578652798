import { Layout, Row, Space } from "antd";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router";
import { AppSidebar } from "./sidebar";
import { UserMultibutton } from "widgets/user-actions";
import { AppContent } from "./content";
import { RequireLogin } from "widgets/require-login";
import { OperatorSiderMenu } from "widgets/operator-sider-menu";
import { usePageView } from "shared/hooks";

interface OperatorLayoutProps {
  withoutSider?: boolean;
}

export default function OperatorLayout({
  children,
  withoutSider = false,
}: PropsWithChildren<OperatorLayoutProps>) {
  const location = useLocation();
  usePageView();

  return (
    <Layout hasSider={!withoutSider}>
      {!withoutSider && (
        <AppSidebar>
          <OperatorSiderMenu selected={location.pathname.substring(1)} />
        </AppSidebar>
      )}
      <Layout
        style={{
          marginLeft: withoutSider ? 0 : 200,
        }}
      >
        <Layout.Header>
          <Row justify="end">
            <Space direction="vertical">
              <UserMultibutton
                extraScope="operator:read operator:write"
                redirectUri="/operator"
              />
            </Space>
          </Row>
        </Layout.Header>
        <AppContent>
          <RequireLogin>{children}</RequireLogin>
        </AppContent>
      </Layout>
    </Layout>
  );
}
