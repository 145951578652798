import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { SessionUser, LoginPayload } from "./types";

type SessionSliceState =
  | {
      accessToken: string;
      user: SessionUser;
      isAuthorized: true;
      scope?: string;
    }
  | {
      isAuthorized: false;
      accessToken?: string;
      user?: SessionUser;
      scope?: undefined;
    };

const initialState: SessionSliceState = {
  isAuthorized: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialState as SessionSliceState,
  reducers: {
    clearSessionData: (state) => {
      state.accessToken = undefined;
      state.user = undefined;
      state.isAuthorized = false;
      state.scope = undefined;
    },
    sessionLoginDetected: (_, action: PayloadAction<LoginPayload>) => {
      return {
        isAuthorized: true,
        ...action.payload,
      };
    },
  },
});

export const selectIsAuthorized = (state: RootState) =>
  state.session.isAuthorized;

export const selectScope = (state: RootState) => state.session.scope;

export const selectUser = (state: RootState) => state.session.user;

export const { clearSessionData, sessionLoginDetected } = sessionSlice.actions;
