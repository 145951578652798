import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./base-query";
import {
  COMPLETED_OPERATOR_REQUESTS,
  COMPONENTS_STATUS_REQUEST,
  DEPLOYMENTS_TAG,
  DEPLOYMENT_INFO_REQUESTS,
  OPERATOR_DEPLOYMENT_INFO_REQUESTS,
  OPERATOR_REQUESTS,
  PREPARED_OPERATOR_REQUESTS,
} from "./tags";

export const baseApi = createApi({
  reducerPath: "api",
  tagTypes: [
    DEPLOYMENTS_TAG,
    OPERATOR_REQUESTS,
    PREPARED_OPERATOR_REQUESTS,
    COMPLETED_OPERATOR_REQUESTS,
    DEPLOYMENT_INFO_REQUESTS,
    OPERATOR_DEPLOYMENT_INFO_REQUESTS,
    COMPONENTS_STATUS_REQUEST,
  ],
  baseQuery,
  endpoints: () => ({}),
});
